import React from 'react'

import Layout from '../components/layout'
import { AboutUs } from '../components/aboutUs'
import { Team } from '../components/team'
import { Newsletter } from '../components/common/newsletter' 
import { CustomerComments } from '../components/customerComments'
import Seo from '../components/seo'


const BydgoszczAboutUs = () => (
  <Layout bydgoszcz>
    <Seo
      title="O nas - klinika modelowania sylwetki i kosmetologii estetycznej"
      description="Laseroterapia, modelowanie sylwetki oraz kosmetologia estetyczna. Poznaj nasz zespół i pełną ofertę zabiegów w klinice BodyMed Group Bydgoszcz." 
      url="https://bodymedgroup.pl/bydgoszcz-o-nas"
    />
    <AboutUs bydgoszcz/>
    <Team bydgoszcz/>
    <Newsletter />
    <CustomerComments />
  </Layout>
)

export default BydgoszczAboutUs
